import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMagic,
  faGlobeAmericas,
  faLongArrowAltLeft,
} from '@fortawesome/free-solid-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

import './styles.scss'

const Link = ({
  label,
  link,
  linkStyle = 'site',
  openNewTab = true,
  disabled,
  ...otherProps
}) => {
  let icon = null
  if (linkStyle === 'site') {
    icon = <FontAwesomeIcon className="icon-fa" icon={faGlobeAmericas} />
  } else if (linkStyle === 'feature') {
    icon = <FontAwesomeIcon className="icon-fa" icon={faMagic} />
  } else if (linkStyle === 'github') {
    icon = <FontAwesomeIcon className="icon-fa" icon={faGithub} />
  } else if (linkStyle === 'back') {
    icon = <FontAwesomeIcon className="icon-fa" icon={faLongArrowAltLeft} />
  }

  return !disabled ? (
    <a
      className={`portfolio-link`}
      href={link}
      {...otherProps}
      target={openNewTab ? '_blank' : ''}
      rel={openNewTab ? 'noreferrer' : ''}
    >
      {icon}
      <span>{label}</span>
    </a>
  ) : (
    <a className={`portfolio-link portfolio-link-disabled`} {...otherProps}>
      {icon}
      <span>{label}</span>
    </a>
  )
}

export default Link
